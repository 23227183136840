<template>
  <div v-if="!loading" class="erp-window-panel">
    <div class="wrapper-md flex justify-between r-nav-right">
      <div class="navigation r-nav">
        <div class="tab" :class="{selected: tab === 'resumo'}" @click="tab = 'resumo'">Resumo</div>
        <div class="tab" :class="{selected: tab === 'seguranca'}" @click="tab = 'seguranca'">Segurança</div>
<!--        <div class="tab" :class="{selected: tab === 'funcoes'}"  @click="tab = 'funcoes'">Funções</div>-->
        <!--        <div class="tab">Financeiro</div>-->
      </div>
    </div>
    <div v-if="tab === 'resumo'" class="content">
      Estamos evoluindo a tela de cadastro do arrematante para fornecer o máximo de informações e funções integradas. Nos próximos dias estaremos lançando a versão beta, aguarde.
      <div class="arrematante-extra-btns m-t-lg">
        <button disabled>
          <div class="icon"><i class="fa fa-file-pdf"></i></div>
          <div class="legend">Imprimir Histórico</div>
        </button>
        <button disabled>
          <div class="icon"><i class="fa fa-envelope"></i></div>
          <div class="legend">Enviar e-mail</div>
        </button>
        <button disabled>
          <div class="icon"><i class="fa fa-passport"></i></div>
          <div class="legend">Enviar senha</div>
        </button>
        <button disabled>
          <div class="icon"><i class="fa fa-print"></i></div>
          <div class="legend">Gerar Doumento</div>
        </button>
      </div>
    </div>
    <div v-if="tab === 'seguranca'" class="content">
      <analise-cadastral-btn />
      <div class="arrematante-extra-btns m-t">
        <button disabled>
          <div class="icon"><i class="fa fa-money-check"></i></div>
          <div class="legend">Consulta SPC/Serasa</div>
        </button>
        <button disabled>
          <div class="icon"><i class="fa fa-envelope"></i></div>
          <div class="legend">Outras Consultas</div>
        </button>
      </div>
    </div>
  </div>
  <div v-else class="erp-window-panel text-center loading">
    <div class="wrapper-xl">
      <u-fake-loader width="20%" height="4px"/>
      <br>
      <br>
      <u-fake-loader width="100%" height="4px"/>
      <u-fake-loader width="100%" height="10px"/>
      <u-fake-loader width="100%" height="8px"/>
      <u-fake-loader width="100%" height="20px"/>
    </div>
  </div>
</template>

<script>
import {UFakeLoader} from "uloc-vue"
import AnaliseCadastralBtn from "components/arrematante/components/window/components/AnaliseCadastralBtn.vue"
import ArrematanteExtraMixin from "components/arrematante/components/window/components/ArrematanteExtraMixin"

export default {
  name: "ArrematanteExtra",
  mixins: [ArrematanteExtraMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tab: 'resumo',
    }
  },
  mounted() {
    this.$el.parentNode.parentNode.parentNode.parentNode.prepend(this.$el)
  },
  beforeDestroy() {
    this.$el && this.$el.parentNode && this.$el.parentNode.parentNode.parentNode.parentNode && this.$el.parentNode.parentNode.parentNode.parentNode.contains(this.$el) && this.$el.parentNode.parentNode.parentNode.parentNode.removeChild(this.$el)
  },
  methods: {
  },
  components: {
    AnaliseCadastralBtn,
    UFakeLoader
  }
}
</script>
