<script>
  import {UAlert, UTooltip} from 'uloc-vue'
  import {
    STATUS_ANALISE_PENDENTE,
    STATUS_MOVIDA_PARA_OUTRO_USUARIO,
    STATUS_VISTA_PARA_ARREMATANTE
  } from '../../../../domain/arrematante/helpers/arrematante-analise-status'
  import {iniciarAnaliseCadastral, aprovarCadastroArrematante, updateAnalise, requisitarAnalise} from '../../../../domain/arrematante/services/arrematante-analise'
  import {STATUS} from '../../../../domain/arrematante/helpers/arrematante-documento-status'
  import {analisesCadastrais} from "@/domain/arrematante/services";

  export default {
    name: 'AnaliseCadastral',
    props: ['arrematante'],
    components: {UAlert, UTooltip},
    data () {
      return {
        data: {
          id: null,
          status: STATUS_ANALISE_PENDENTE,
          arrematante: this.arrematante.id,
          motivos: []
        },
        motivoDigitado: null,
        STATUS_VISTA_PARA_ARREMATANTE
      }
    },
    computed: {
      isUsuarioAtualAnalisando () {
        return this.$uloc.auth.session.user.id === this.data.createdBy
      },
      usuarioAnalisando () {
        const {createdByUser} = this.data
        if (!createdByUser) {
          return '-'
        }
        return this.isUsuarioAtualAnalisando ? 'você' : createdByUser
      },
      documentosRequeridosPendentes () {
        let documentosEnviados = this.arrematante.documentos.map((el) => {
          return el.tipo.id
        }).filter(function (item, pos, self) {
          return self.indexOf(item) === pos
        })
        return this.arrematante.documentosRequeridos.filter(function (item) {
          return documentosEnviados.indexOf(item.id) === -1
        })
      },
      isAptoParaAprovacao () {
        const possuiDocumentoObrigatorioNaoEnviado = this.documentosRequeridosPendentes.some(item => item.obrigatorio)
        const possuiDocumentoNaoAprovado = this.arrematante.documentos.some(item => item.status !== 2)

        return !possuiDocumentoObrigatorioNaoEnviado && !possuiDocumentoNaoAprovado
      },
      motivosNaoAptoParaAprovacao () {
        if (this.motivoDigitado) {
          return [this.motivoDigitado]
        }
        const documentosNaoAprovados = this.arrematante.documentos.filter(item => item.status !== 2)
        const documentosObrigatoriosNaoEnviados = this.documentosRequeridosPendentes.filter(item => item.obrigatorio)

        const motivosDocumentosNaoAprovados = documentosNaoAprovados.reduce((acc, item)=> {
          const motivo = `${item.tipo?.nome} está ${STATUS[item.status]?.toLowerCase()}`
          acc.push(motivo)
          return acc
        }, [])

        const motivosDocumentosObrigatoriosNaoEnviados = documentosObrigatoriosNaoEnviados.reduce((acc, item) => {
          const motivo = `${item.nome} é obrigatório e não foi enviado`
          acc.push(motivo)
          return acc
        }, [])

        return [...motivosDocumentosNaoAprovados, ...motivosDocumentosObrigatoriosNaoEnviados]
      }
    },
    created () {
      console.log('Arrematante', this.arrematante)
      const {analises} = this.arrematante
      if (analises.length) {
        console.log('SETOU ANÁLISE')
        this.data = analises.find(analise => analise.status !== STATUS_MOVIDA_PARA_OUTRO_USUARIO) // JSON.parse(JSON.stringify(analise))
        this.data.arrematante = this.arrematante.id
      }
    },
    methods: {
      confirmIniciarAnalise () {
        this.confirmDialog(`Iniciar análise de cadastro para ${this.arrematante.pessoa.name}?`, '', this.submitAnalise)
      },
      submitAnalise () {
        iniciarAnaliseCadastral(this.data)
                .then(response => {
                  this.data = response.data
                  this.data.arrematante = this.data.arrematante.id
                  console.log(this.data)
                  this.$uloc.notify({color: 'positive', message: `O cadastro deste usuário está sendo analisado por você a partir de agora`})
                })
                .catch(error => {
                  this.$uloc.notify({color: 'negative', message: 'Ocorreu um erro ao registrar'})
                })
      },
      confirmAprovarCadastro () {
        this.confirmDialog('Confimar aprovação de cadastro deste arrematante?', '', this.aprovarCadastro)
      },
      aprovarCadastro () {
        aprovarCadastroArrematante(this.arrematante.id)
                .then(response => {
                  this.$uloc.notify({color: 'positive', message: 'O cadastro foi aprovado com sucesso!'})
                  this.$emit('alterarStatus', response.data.status)
                })
                .catch(error => {
                  this.$uloc.notify({color: 'negative', message: 'Ocorreu um erro ao registrar'})
                    console.log(error)
                })
      },
      confirmDarVistas () {
        this.confirmDialog('Confimar envio de vista ao arrematante?', '', this.darVistaParaArrematante)
      },
      darVistaParaArrematante () {
        const data = JSON.parse(JSON.stringify(this.data))
        data.status = STATUS_VISTA_PARA_ARREMATANTE
        data.motivo = this.motivosNaoAptoParaAprovacao

        if (data.arrematante && data.arrematante.id) {
          data.arrematante = data.arrematante.id
        }

        updateAnalise(this.data.id, data)
                .then(response => {
                  this.$uloc.notify({color: 'positive', message: 'Aviso de vista enviado com sucesso!'})
                  this.data.status = STATUS_VISTA_PARA_ARREMATANTE
                })
                .catch(error => {
                  this.$uloc.notify({color: 'negative', message: 'Ocorreu um erro ao registrar'})
                    console.log(error)
                })
      },
      confirmRequisitarAnalise () {
        this.confirmDialog('Confirmar transferência de análise deste arrematante para você?', '', this.requisitarAnalise)
      },
      requisitarAnalise () {
        requisitarAnalise(this.data.id)
                .then(response => {
                  this.$uloc.notify({color: 'positive', message: 'Análise transferida com sucesso!'})
                  this.data = response.data
                })
                .catch(error => {
                  this.$uloc.notify({color: 'negative', message: 'Ocorreu um erro ao registrar'})
                  console.log(error)
                })
      },
      editarMotivos () {
        this.$uloc.dialog({
          title: `Editar motivos para usuário não estar apto`,
          message: 'Digite os motivos que tornam este usuário inapto a participar de leilões',
          prompt: {
            model: this.motivosNaoAptoParaAprovacao.join('; '),
            type: 'text'
          },
          cancel: true,
          color: 'negative'
        })
                .then(data => {
                  this.motivoDigitado = data
                })
                .catch(() => {})
      },
      confirmDialog (title, message, callback) {
        this.$uloc.dialog({
          title,
          message,
          cancel: true,
          color: 'negative'
        })
          .then(() => {
            this.$uloc.notify({color: 'warning', message: `Registrando, aguarde...`})
            callback()
          })
          .catch(() => {})
      }
    }
  }
</script>

<template>
  <div class="w-full">
    <!-- Se ArrematanteAnalise não existe -->
    <u-btn color="orange" push class="full-width m-b" label="Iniciar análise cadastral" v-if="!data.id" @click="confirmIniciarAnalise"/>

    <!-- Se ArrematanteAnalise existe. Atenção para caso seja o próprio usuário logado. Verificar no $uloc.auth.session.user.id === checkoutBy -->
    <u-alert color="black" text-color="white" class="w-full m-b" v-else>
      <div v-if="data.active" class="font-14">Este cadastro está sendo analisado por <strong>{{usuarioAnalisando}}</strong> desde {{data.createdAt.date|formatDate}}.
        <u-btn
                class="m-l"
                color="white"
                text-color="blue-grey-6"
                size="sm" icon="exchange"
                icon-type="fa"
                label="Requisitar esta análise"
                v-if="!isUsuarioAtualAnalisando"
                @click="confirmRequisitarAnalise"
        /></div>
      <div v-else>
        Cadastro aguardando início da análise desde {{data.createdAt.date|formatDate}}
        <u-btn color="orange" push class="m-l-md" label="Iniciar análise cadastral" @click="confirmRequisitarAnalise"/>
      </div>
    </u-alert>

    <!-- Computed prop analisando docs e seus respectivos status. Se não for apto para ser aprovado -->
    <u-alert color="warning" text-color="black" class="w-full m-b" v-if="!isAptoParaAprovacao">
      <div class="font-14 flex justify-between flex-center">
        <div class="text-black">Este cadastro <strong class="text-negative">não está</strong> apto para aprovação. Motivo(s):
          <u-btn
                  class="m-l"
                  color="positive"
                  size="xs"
                  icon="edit"
                  icon-type="fa"
                  label="Editar motivos"
                  @click="editarMotivos"
                  v-if="isUsuarioAtualAnalisando"
          />
          <div class="m-t-sm">
            <strong>{{motivosNaoAptoParaAprovacao.join('; ')}}</strong>
          </div>
        </div>
        <u-btn
                color="primary"
                size="sm"
                icon="file-import"
                icon-type="fa"
                label="Dar vista para arrematante"
                v-if="isUsuarioAtualAnalisando && (data.status !== STATUS_VISTA_PARA_ARREMATANTE)"
                @click="confirmDarVistas"
        >
          <u-tooltip content-class="bg-indigo" :offset="[10, 10]">
            Envia uma notificação ao arrematante para reavaliar as pendências de documentação para uma reanálise
          </u-tooltip>
        </u-btn>
        <u-btn
                color="primary"
                size="sm"
                icon="check"
                icon-type="fa"
                label="Notificação de vista enviada"
                @click="confirmDarVistas"
                v-else-if="data.status === STATUS_VISTA_PARA_ARREMATANTE"
        >
          <u-tooltip content-class="bg-indigo" :offset="[10, 10]">
            Enviada uma notificação ao arrematante para reavaliar as pendências de documentação para uma reanálise
          </u-tooltip>
        </u-btn>
      </div>
    </u-alert>

    <!-- Computed prop analisando docs e seus respectivos status. Se for apto para ser aprovado -->
    <u-alert color="positive" class="w-full m-b" v-else>
      <div class="font-14 flex justify-between flex-center">
        <div class="text-black">Este cadastro <strong class="text-black">está apto</strong> para aprovação.</div>
        <u-btn color="black" size="sm" icon="check" label="Aprovar cadastro" v-if="isUsuarioAtualAnalisando" @click="confirmAprovarCadastro"/>
      </div>
    </u-alert>
  </div>
</template>
